import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppProvider from './hooks/app';
import Routes from './routes';
import GlobalStyle from './styles/global';

import Wrapper from './components/wrapper';
import { IntlProvider } from 'react-intl';

function App() {
  return (
    <BrowserRouter>
      <Wrapper>
        <AppProvider>
          <Routes />
          <GlobalStyle />
        </AppProvider>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
