import { shade } from 'polished';
import colors from '../../styles/colors';
import styled from 'styled-components';
import signInBackgroundImage from '../../assets/images/login-background.jpg';
import {
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Dialog,
} from '@material-ui/core';

export const Container = styled.div`
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: url(${signInBackgroundImage}) no-repeat center;
  background-size: cover;
`;

export const Content = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  form {
    height: 300px;
    margin: 40px 0;
    padding: 20px;
    width: 450px;
    text-align: center;
    background-color: ${colors.light};
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    row-gap: 15px;
    h1 {
      margin-bottom: 24px;
    }
    a {
      color: ${colors.secondary};
      display: block;
      // margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      cursor: pointer;
      &:hover {
        color: ${shade(0.2, colors.secondary)};
      }
    }
  }
  > a {
    color: ${colors.secondary};
    display: block;
    text-decoration: none;
    transition: color 0.2s;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, colors.secondary)};
    }
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #039be5;
    color: white;
    padding-right: 16px;
    padding-left: 10px;
    height: 50px;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  && {
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const CustomeTextField = styled(TextField)`
  && {
    margin-bottom: 30px;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    padding-bottom: 15px;
    padding-right: 20px;
  }
`;

// Custom styled Dialog component
export const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 10px; /* Adjust the border radius as needed */
  }
`;

export const Logo = styled.img`
  width: 100px;
  position: relative;
  top: -12px;
  left: 10%;
  transform: translateX(-50%);
  z-index: 1100;
`;

export const Text = styled.div`
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 400;
`;

export const StyledDialogErrorTitle = styled(DialogTitle)`
  && {
    display: flex;
    align-items: center;
    background-color: white;
    color: red;
    height: 60px;
    width: 350px;
    .MuiTypography-h6 {
      font-size: 1.05rem;
    }
  }
`;

export const LogoDialog = styled.img`
  width: 100px;
  position: relative;
  bottom: 320px;
  z-index: 1100;
`;
