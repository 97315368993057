import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

const toastTypeVariations = {
  info: css`
    background: #ebf8ff;
    color: #3172b7;
    /* border-left: 8px solid #3172b7;
    border-right: 8px solid #3172b7; */
  `,
  success: css`
    background: #e6fffa;
    color: #2e656a;
    /* border-right: 8px solid #2e656a;
    border-left: 8px solid #2e656a; */
  `,
  error: css`
    background: #fddede;
    color: #c53030;
    /* border-left: 8px solid #c53030;
    border-right: 8px solid #c53030; */
  `,
};
export const Content = styled(animated.div)`
  width: 320px;
  position: relative;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 10000;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  & + div {
    margin-top: 8px;
  }
  ${(props) => toastTypeVariations[props.type || 'info']}
  > svg {
    margin: 4px 12px 0 0;
  }

  div {
    flex: 1;
    p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 15px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }
  ${(props) =>
    !props.hasdescription &&
    css`
      align-items: center;
    `}
`;
