import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const RemoveContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;
export const ArchiveContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
`;
export const Sidebar = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background-color: #f9f9f9;
  flex: 1;
  z-index: ${(props) => (props.open ? 1000 : -1)};
`;

export const SidebarLeft = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 425px;
  background-color: #f9f9f9;
  flex: 1;
  z-index: ${(props) => (props.open ? 1000 : -1)};
`;

export const Container = styled.div`
  opacity: ${(props) => (props.open ? 1 : 0)};
  position: absolute;
  top: 59px;
  bottom: 0px;
  right: 0px;
  width: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const MembersContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 10px;
  flex-direction: column;
  row-gap: 5px;
  background-color: #fff;
  margin-top: 3px;
  span {
    color: #666;
  }
`;
export const InfoContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 30px 10px;
  place-content: center;
  place-items: center;
  flex-direction: column;
  row-gap: 5px;
  background-color: #fff;
  h6 {
    color: #666;
    align-self: flex-start;
  }
`;

export const NameContainer = styled.div`
  position: relative;
  margin-top: 10px;
  align-self: flex-start;
  display: flex;
  color: #666;
  align-items: center;
  width: 100%;
  svg {
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  input {
    border: none;
    border-bottom: 1px solid #999;
    color: #666;
    padding: 5px;
    width: 80%;
  }
`;
export const IconContainer = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 120px;
  background-color: #f0f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    color: #666;
    font-size: 60px;
  }
`;

export const Header = styled.div`
  width: 400px;
  height: 60px;
  background: #039be5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  column-gap: 10px;
  align-items: center;
  color: #fff;
  svg {
    color: #fff;
  }
`;
