import React, { createContext, useCallback, useContext, useState } from 'react';
import { v4 } from 'uuid';
import Toast from '../components/toastcontainer';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const addToast = useCallback(({ type, title, description }) => {
    const id = v4();
    const toast = {
      id,
      type,
      title,
      description,
    };
    setMessages((state) => [...state, toast]);
  }, []);

  const removeToast = useCallback((id) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);
  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <Toast messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast() {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}
export { ToastProvider, useToast };
