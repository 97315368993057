import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import colors from '../../styles/colors';

const AlertDialog = ({ open, type, title, message, handleCancel }) => {
  const intl = useIntl();

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title"> {title} </DialogTitle>
      <DialogContent>
        <span>{message}</span>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="outlined" size="small">
          {intl.formatMessage({ id: 'Ok' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
