import React, { useCallback, useEffect, useState } from 'react';
import { Sidebar, Container, Header, ItemList } from './styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton } from '@material-ui/core';
import { useContacts } from '../../hooks/contacts';
import { GetOrCreateConversationByContactInformation } from '../../services/conversations';
import { giveNames, sortList } from '../../utils';
import {
  GetGroupedContactInformation,
  SearchUserDirectory,
} from '../../services/staff';
import { useGroups } from '../../hooks/groups';
import { useChatMessages } from '../../hooks/chat';

import { useIntl } from 'react-intl';

const ListContacts = ({ clearFilterChats }) => {
  const { menu, setMenu } = useContacts();
  const [persons, setPersons] = useState([]);
  const [personsFiltered, setPersonsFiltered] = useState([]);
  const [filter, setFilter] = useState([]);
  const { setSelecetedConversation } = useChatMessages();
  const { staffGroups, reloadGroups } = useGroups();
  const reloadFields = () => {
    setPersons([]);
    setPersonsFiltered([]);
    setFilter('');
  };

  const intl = useIntl();

  useEffect(() => {
    setFilter('');
  }, []);
  const onFilter = useCallback((value) => {
    setFilter(value);
  }, []);

  useEffect(() => {
    setPersonsFiltered([]);
    const load = async () => {
      const sortedList = (
        await SearchUserDirectory({ searchString: filter })
      ).sort((a, b) =>
        a.LastName > b.LastName ? 1 : a.LastName < b.LastName ? -1 : 0
      );
      setPersonsFiltered(sortedList);
    };
    if (filter.length >= 3) {
      load();
    }
  }, [filter]);
  const onGetOrCreateConversationByContactInformation = useCallback(
    async (contact) => {
      await GetOrCreateConversationByContactInformation({
        contactInformation: contact,
      }).then((data) => {
        clearFilterChats();
        setSelecetedConversation({
          ...data.conversation,
          isGroup: contact.isGroup,
          UserDefined: contact.UserDefined,
        });
        setMenu(false);
        reloadFields();
      });
    },
    [setMenu, setSelecetedConversation]
  );
  return (
    <Sidebar open={menu}>
      <Header>
        <IconButton onClick={() => setMenu(false)}>
          <ArrowBackIcon />
        </IconButton>
        {intl.formatMessage({ id: 'NewConversation' })}
      </Header>
      <input
        placeholder={intl.formatMessage({ id: 'SearchHere' })}
        value={filter}
        onChange={(e) => onFilter(e.currentTarget.value)}
      />
      <Container open={menu}>
        {personsFiltered.map((item, key) => {
          return (
            <ItemList
              key={key}
              onClick={() =>
                onGetOrCreateConversationByContactInformation(item)
              }
            >
              {!item.isGroup && (
                <div>
                  {`${item.FirstName} `}
                  <span style={{ fontWeight: 'bolder' }}>{item.LastName}</span>
                </div>
              )}
              {item.isGroup && <div>{`${item.Name}`}</div>}
            </ItemList>
          );
        })}
      </Container>
    </Sidebar>
  );
};

export default ListContacts;
