/* eslint-disable prettier/prettier */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { FaGlobe } from 'react-icons/fa';
import { Context } from '../wrapper';
import { useIntl } from "react-intl";

import { Button, Container } from './style';


export default function ChangeLanguage(props) {
  const intl = useIntl()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Context.Consumer>
      {(context) => {
        //console.log(context);
        return (
          <>
            <FaGlobe />
            <Container
              space= {props.space}
              value={context.locale}
              onChange={context.selectLanguage}
              renderValue={(value) =>
                value.toUpperCase()
              }
            >
              <MenuItem value="cs">Čeština</MenuItem>
              <MenuItem value="de">Deutsch</MenuItem>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fr">Français</MenuItem>
              <MenuItem value="hr">Hrvatski</MenuItem>
              <MenuItem value="it">Italiano</MenuItem>
              <MenuItem value="pt">Português</MenuItem>
              <MenuItem value="sk">Slovenčina</MenuItem>
              <MenuItem value="sl">Slovenščina</MenuItem>
            </Container>
          </>
        );
      }}
    </Context.Consumer>
  );
}
