import axios from 'axios';
import { getIdentificationId } from '../storage';
import { REACT_APP_URL } from '../config';
import { getPortalStaffRequestData } from './staff';

const presenceStateUrl = '/PortalStaff/ios/SetPresenceState';
const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 25000,
});

export const setPresenceStateLogin = async () => {
  const identificationId = getIdentificationId();
  api.defaults.baseURL = REACT_APP_URL;
  const data = getPortalStaffRequestData(identificationId, {
    presenceState: 1,
  });
  const response = await api.post('/PortalStaff/ios/SetPresenceState', data);
};
