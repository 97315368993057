import React, { useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, DialogContentText } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  StyledDialogTitle,
  StyledIconButton,
  StyledDialogActions,
  StyledDialogContent,
  CustomeTextField,
  CustomDialog,
} from './styles';
import { useAuth } from '../../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { ResetPassword } from '../../../services/auth';
import { useToast } from '../../../hooks/toast';
import AlertDialog from '../../alertdialog';

const ChangePasswordDialog = ({ open, handleCancel }) => {
  const { addToast } = useToast(false);
  const intl = useIntl();
  const { setPortal } = useAuth();
  const history = useHistory();

  const [userName, setUserName] = React.useState('');
  const [userNameError, setUserNameError] = React.useState('');

  // State for managing alert dialog visibility
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    setPortal(null);
    history.push('/');
  }, [history, setPortal]);

  const handleSave = async () => {
    let hasError = false;

    if (!userName) {
      setUserNameError(intl.formatMessage({ id: 'UserNameRequired' }));
      hasError = true;
    } else {
      setUserNameError('');
    }

    if (!hasError) {
      const result = await ResetPassword({ userName });
      if (result.ResetPasswordResult) {
        setAlertMessage(
          intl.formatMessage({ id: 'NewPasswordsSentToYourEmail' })
        );
        setOpenAlertDialog(true);
        cancel();
        logout();
      } else {
        setAlertMessage(intl.formatMessage({ id: 'EnterValidUserName' }));
        setOpenAlertDialog(true);
        cancel();
      }
    }
  };

  const handleUserNameBlur = () => {
    if (!userName) {
      setUserNameError(intl.formatMessage({ id: 'UserNameRequired' }));
    } else {
      setUserNameError('');
    }
  };

  const cancel = () => {
    setUserName('');
    setUserNameError('');
    handleCancel();
  };

  return (
    <>
      <CustomDialog open={open} maxWidth="sm" fullWidth={true}>
        <StyledDialogTitle>
          <StyledIconButton aria-label="close" onClick={cancel}>
            <CloseIcon />
          </StyledIconButton>
          {intl.formatMessage({ id: 'ResetPassword' })}
        </StyledDialogTitle>
        <StyledDialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: 'ResetPasswordLabel' })}
          </DialogContentText>
          <CustomeTextField
            id="old-password"
            fullWidth
            label={intl.formatMessage({ id: 'UserName' })}
            value={userName}
            color="secondary"
            error={Boolean(userNameError)}
            helperText={userNameError}
            onChange={(e) => setUserName(e.target.value)}
            onBlur={handleUserNameBlur}
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <Button variant="outlined" color="secondary" onClick={handleSave}>
            {intl.formatMessage({ id: 'ResetPassword' })}
          </Button>
        </StyledDialogActions>
      </CustomDialog>
      <AlertDialog
        handleCancel={() => setOpenAlertDialog(false)}
        message={alertMessage}
        open={openAlertDialog}
      />
    </>
  );
};

export default ChangePasswordDialog;
