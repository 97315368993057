import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useEffect, useState } from 'react';
import { Body, Header, HeaderTitle, ItemList, Title } from './styles';
import {
  GetGroupedContactInformation,
  GetStaffInformationForStaffGroup,
} from '../../../services/staff';
import CheckIcon from '@material-ui/icons/Check';

import { giveNames, sortList } from '../../../utils';
import { AddMembersToManualStaffGroup } from '../../../services/conversations';
import { ContactsList } from '../../contactslist';

import { useIntl } from 'react-intl';

const Contacts = ({ open, handleClose, reload, GroupGuid }) => {
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    const init = async () => {
      let members = [];
      setSelectedContacts([]);
      const responseMembers = await GetStaffInformationForStaffGroup({
        staffGroupGuid: GroupGuid,
      });
      if (responseMembers.GetStaffInformationForStaffGroupResult) {
        responseMembers.staffContactInformationList.forEach((i) => {
          members.push({
            StaffGuid: i.StaffGuid,
          });
        });
      }
      const response = await GetGroupedContactInformation();
      if (response.GetGroupedContactInformationResult) {
        let list = [];
        response.staffContactInformationList.forEach((contact) => {
          let names = giveNames(contact.Name);
          if (!members.some((m) => m.StaffGuid === contact.StaffGuid))
            list.push({
              ...contact,
              LastName: names.LastName,
              Name: names.Name,
              isGroup: false,
            });
        });
        sortList(list);
        setContacts(list);
      }
    };
    if (GroupGuid) init();
  }, [GroupGuid]);

  const close = useCallback(() => {
    setContacts([]);
    setSelectedContacts([]);
    handleClose();
  }, [handleClose]);

  const addMembers = useCallback(async () => {
    await AddMembersToManualStaffGroup({
      staffGroup: { GroupGuid: GroupGuid },
      toBeIncludedMembers: selectedContacts.map((item) => ({
        StaffGuid: item.StaffGuid,
      })),
    });
    await reload();
    setSelectedContacts([]);
  }, [selectedContacts, GroupGuid]);
  const selectItem = useCallback(
    (item) => {
      if (!selectedContacts.find((i) => i.StaffGuid === item.StaffGuid)) {
        setSelectedContacts([...selectedContacts, item]);
      } else {
        setSelectedContacts(
          selectedContacts.filter((i) => i.StaffGuid !== item.StaffGuid)
        );
      }
    },
    [selectedContacts]
  );

  const checkItem = useCallback(
    (item) => {
      return selectedContacts.find((i) => i.StaffGuid === item.StaffGuid);
    },
    [selectedContacts]
  );
  return (
    <Dialog open={open} onClose={close} maxWidth={'sm'}>
      <Header>
        <CloseIcon onClick={close} />
        <HeaderTitle>{intl.formatMessage({ id: 'Contacts' })}</HeaderTitle>
      </Header>
      <DialogContent>
        <Body>
          <ContactsList
            selectItem={selectItem}
            selectedItems={selectedContacts}
          />
        </Body>
      </DialogContent>
      {contacts.length > 0 && (
        <DialogActions>
          <Button autoFocus onClick={close} color="primary">
            {intl.formatMessage({ id: 'Cancel' })}
          </Button>
          <Button color="secondary" onClick={addMembers}>
            {intl.formatMessage({ id: 'AddMembers' })}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Contacts;
