import { v4 } from 'uuid';
import {
  getConfigurationId,
  getIdentificationId,
  getInstanceId,
  setInstanceId,
} from '../../storage';
const getInstance = ({ IpAddress }) => {
  let InstanceId = getInstanceId();
  let ConfigurationGuid = getConfigurationId();
  if (!InstanceId) {
    InstanceId = v4();
    setInstanceId({ InstanceId });
  }
  // console.log(InstanceId);
  return {
    InstanceId,
    version: '1.0.61',
    ConfigurationGuid,
    ApplicationType: '4000',
    PlatformType: '4',
    IpAddress,
  };
};

const IdentificationId = () => {
  return {
    identificationId: getIdentificationId(),
    versionNumber: '1.0.61',
    appType: 4000,
    PlatformType: 7,
    // PlatformType: 4,
  };
};

export { getInstance, IdentificationId };
