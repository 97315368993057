import React, { useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import { ArrowDropDownOutlined } from '@material-ui/icons';
import { IconButton, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useGroups } from '../../hooks/groups';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import { useIntl } from 'react-intl';

const MenuSettings = ({ id, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { setMenuGroups } = useGroups();
  const { setPortal } = useAuth();
  const history = useHistory();

  const intl = useIntl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    setPortal(null);
    history.push('/');
  }, [history, setPortal]);

  return (
    <div>
      <IconButton
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon size="small" color="inherit" />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={async () => {
            setMenuGroups(true);
            handleClose();
          }}
        >
          New Group
        </MenuItem>
        <MenuItem onClick={logout}>
          {intl.formatMessage({ id: 'Logout' })}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MenuSettings;
