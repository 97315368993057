import { shade } from 'polished';
import styled from 'styled-components';
import colors from '../../styles/colors';
import signInBackgroundImage from '../../assets/images/login-background.jpg';
import { TextField } from '@material-ui/core';
export const Input = styled(TextField)`
  width: 100%;
`;

export const Container = styled.div`
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background: url(${signInBackgroundImage}) no-repeat center;
  background-size: cover;
`;

export const Text = styled.div`
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 400;
`;
export const Content = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  form {
    height: 300px;
    margin: 40px 0;
    padding: 20px;
    width: 450px;
    text-align: center;
    background-color: ${colors.light};
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    row-gap: 15px;
    h1 {
      margin-bottom: 24px;
    }
    a {
      color: ${colors.secondary};
      display: block;
      // margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      cursor: pointer;
      &:hover {
        color: ${shade(0.2, colors.secondary)};
      }
    }
  }
  > a {
    color: ${colors.secondary};
    display: block;
    text-decoration: none;
    transition: color 0.2s;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
    &:hover {
      color: ${shade(0.2, colors.secondary)};
    }
  }
`;

export const Logo = styled.img`
  width: 100px;
`;
