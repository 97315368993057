import { Select } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Select)`
  margin-left: ${(props) => props.space};
  font-size: 14px;
  ::before {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  ::after {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  ::-ms-expand {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;
