import React, { useCallback, useEffect, useState } from 'react';
import { GetStaffInformationForStaffGroup } from '../../../services/staff';
import { Container, ItemList, Status } from './styles';

import { giveNames, sortList } from '../../../utils';
import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { RemoveMembersFromManualStaffGroup } from '../../../services/conversations';
import { getUserName } from '../../../storage';

import { useIntl } from 'react-intl';

const Members = ({ GroupGuid, canEdit, reload }) => {
  const intl = useIntl();

  const [persons, setPersons] = useState([]);
  const [personsFiltered, setPersonsFiltered] = useState([]);
  const [filter, setFilter] = useState([]);
  const reloadMembers = async () => {
    const response = await GetStaffInformationForStaffGroup({
      staffGroupGuid: GroupGuid,
    });
    if (response.GetStaffInformationForStaffGroupResult) {
      let list = [];
      let names = giveNames(getUserName());
      list.push({
        LastName: names.LastName,
        Name: names.Name,
        my: true,
      });
      response.staffContactInformationList.forEach((contact) => {
        names = giveNames(contact.Name);
        list.push({
          ...contact,
          LastName: names.LastName,
          Name: names.Name,
          isGroup: false,
        });
      });
      sortList(list);
      setPersons(list);
      setPersonsFiltered(list);
    }
  };
  useEffect(() => {
    const init = async () => {
      const response = await GetStaffInformationForStaffGroup({
        staffGroupGuid: GroupGuid,
      });
      if (response.GetStaffInformationForStaffGroupResult) {
        let list = [];
        let names = giveNames(getUserName());

        list.push({
          LastName: names.LastName,
          Name: names.Name,
          my: true,
        });
        response.staffContactInformationList.forEach((contact) => {
          //names = giveNames(contact.Name);
          //console.log(names);
          list.push({
            ...contact,
            LastName: contact.LastName,
            Name: contact.FirstName,
            isGroup: false,
          });
        });
        sortList(list);
        setPersons(list);
        setPersonsFiltered(list);
      }
    };
    if (GroupGuid) init();
  }, [GroupGuid]);

  const removeMember = useCallback(
    async (item) => {
      const response = await RemoveMembersFromManualStaffGroup({
        staffGroup: { GroupGuid: GroupGuid },
        toBeExcludedMembers: [
          {
            StaffGuid: item.StaffGuid,
          },
        ],
      });
      await reloadMembers();
      await reload();
    },
    [GroupGuid]
  );
  return (
    <Container>
      {personsFiltered.map((item, key) => {
        return (
          <ItemList key={key}>
            {!item.isGroup && !item.my && (
              <React.Fragment>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Status status={item.OnlineState} />
                  <span>{item.Name}</span>
                  <span style={{ marginLeft: 2, fontWeight: 'bolder' }}>
                    {item.LastName}
                  </span>
                </div>
                {canEdit && !item.my && (
                  <IconButton
                    title={intl.formatMessage({ id: 'RemoveMember' })}
                    onClick={() => removeMember(item)}
                    size="small"
                    style={{ alignSelf: 'flex-end' }}
                  >
                    <HighlightOffIcon style={{ fontSize: 20 }} />
                  </IconButton>
                )}
              </React.Fragment>
            )}
          </ItemList>
        );
      })}
    </Container>
  );
};

export default Members;
