import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import english from '../../languages/english.json';
import portuguese from '../../languages/portuguese.json';
import deutsch from '../../languages/german.json';
import french from '../../languages/french.json';
import italian from '../../languages/italian.json';
import slovak from '../../languages/slovak.json';
import czech from '../../languages/czech.json';
import croatia from '../../languages/croatia.json';
import slovenia from '../../languages/slovenia.json';

export const Context = React.createContext();

const local = navigator.language.split('-')[0];
//const local = 'pt-BR'.split('-')[0];

let lang;
switch (local) {
  case 'en':
    lang = english;
    break;
  case 'pt':
    lang = portuguese;
    break;
  case 'de':
    lang = deutsch;
    break;
  case 'fr':
    lang = french;
    break;
  case 'it':
    lang = italian;
    break;
  case 'cs':
    lang = czech;
    break;
  case 'sk':
    lang = slovak;
    break;
  case 'sl':
    lang = slovenia;
    break;
  case 'hr':
    lang = croatia;
    break;

  default:
    break;
}
const Wrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  function selectLanguage(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    switch (newLocale) {
      case 'en':
        setMessages(english);
        break;
      case 'pt':
        setMessages(portuguese);
        break;
      case 'de':
        setMessages(deutsch);
        break;
      case 'fr':
        setMessages(french);
        break;
      case 'it':
        setMessages(italian);
        break;
      case 'cs':
        setMessages(czech);
        break;
      case 'sk':
        setMessages(slovak);
        break;
      case 'sl':
        setMessages(slovenia);
        break;
      case 'hr':
        setMessages(croatia);
        break;

      default:
        break;
    }
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
