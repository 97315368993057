import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SecurityIcon from '@material-ui/icons/Security';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ChangePasswordDialog from './changePasswordDialog';
import { useStyles } from './styles';

export default function AccountSettings({ anchorEl, handleClose }) {
  const [openChangePasswordDialog, setChangePasswordDialog] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setChangePasswordDialog(true);
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </MenuItem>
      </Menu>
      <ChangePasswordDialog
        handleCancel={() => setChangePasswordDialog(false)}
        open={openChangePasswordDialog}
      />
    </>
  );
}
