import React, { useCallback, useEffect } from 'react';
import publicIp from 'public-ip';
import { Container, Content, Logo, Input, Text } from './styles';
import logo from '../../assets/images/logo.png';
import { useAuth } from '../../hooks/auth';
import { Button, FormControl, Grid } from '@material-ui/core';

import { useIntl, FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import ResetPasswordDialog from '../../components/accountSettings/resetPasswordDialog';

const SignIn = () => {
  const { signIn } = useAuth();
  const [userName, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [openResetPasswordDialog, setResetPasswordDialog] =
    React.useState(false);

  const intl = useIntl();
  const preventDefault = (event) => event.preventDefault();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      let ip = '';
      try {
        ip = await publicIp.v4();
      } catch (error) {
        ip = '';
      }
      await signIn({
        userName,
        password,
        IpAddress: ip,
      });
    },
    [signIn, userName, password]
  );
  return (
    <Container>
      <Content>
        <Logo src={logo} alt="squre" />
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Text>
            {intl.formatMessage({
              id: 'SignIn',
            })}
          </Text>
          <Input
            id="username"
            label={intl.formatMessage({ id: 'UserName' })}
            onChange={(e) => setUsername(e.currentTarget.value)}
          />
          <FormControl>
            <Input
              id="password"
              label={intl.formatMessage({ id: 'password' })}
              type="password"
              autoComplete="new-password"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </FormControl>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                variant="body2"
                onClick={(e) => {
                  e.preventDefault();
                  setResetPasswordDialog(true);
                }}
              >
                Reset password
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            style={{ marginTop: 20 }}
          >
            {intl.formatMessage({ id: 'SignIn' })}
          </Button>
        </form>
        <ResetPasswordDialog
          handleCancel={() => setResetPasswordDialog(false)}
          open={openResetPasswordDialog}
        />
      </Content>
    </Container>
  );
};

export default SignIn;
