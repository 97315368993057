import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  z-index: 10000;
`;
