import React, { useCallback, useEffect } from 'react';
import { MainContainer, MainContent, HeaderChat, Profile } from './styles';
import Conversations from '../conversations';
import { useMain } from '../../hooks/main';
import Groups from '../groups';
const Main = () => {
  const { page, setPage } = useMain();
  const handleChange = useCallback(
    (event, value) => {
      setPage(value);
    },
    [setPage]
  );

  // if identification id not exist => return to login page
  useEffect(() => {
    console.log(localStorage.getItem('@Squre:IdentificationId'));
    if (!localStorage.getItem('@Squre:IdentificationId')) {
      window.location.href = '/';
    }
  }, []);

  return (
    <MainContainer>
      <MainContent>
        <Conversations />
      </MainContent>
    </MainContainer>
  );
};

export default Main;
