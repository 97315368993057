import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import SignIn from '../pages/signin';
import Main from '../pages/main';
import Groups from '../pages/groups';
import Archiveds from '../pages/archiveds';
import GetCallHistoryPage from '../pages/callHistory';
import Talk from '../pages/talk';
// import MakeCallIndividual from '../components/call/individual_call';
import JitsiCall from '../pages/jitsi/index.js';

import PasswordReset from '../pages/resetpassword';
import PasswordSet from '../pages/setpassword';
const Routes = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/app" exact component={Main} />
    <Route path="/contacts" exact component={Groups} />
    <Route path="/archiveds" exact component={Archiveds} />
    <Route path="/call/history" exact component={GetCallHistoryPage} />
    <Route path="/talk" exact component={Talk} />
    {/* <Route path="/make-call/:id" exact component={MakeCallIndividual} /> */}
    <Route path="/jitsi/:id" exact component={JitsiCall} />
    <Route path="/reset-password" exact component={PasswordReset} />
    <Route path="/set-password-firsttimeuser" exact component={PasswordSet} />
  </Switch>
);

export default Routes;
