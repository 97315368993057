import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  GetGroupedContactInformation,
  GetManualStaffGroups,
  GetStaffInformation,
} from '../services/staff';
import { useAuth } from './auth';

const GroupsContext = createContext();
const GroupsProvider = ({ children }) => {
  const [groups, setGroups] = useState([]);
  const [staffGroupMemberships, setStaffGroupMemberships] = useState([]);
  const [staffGroups, setStaffGroups] = useState([]);
  const [menuGroups, setMenuGroups] = useState(false);
  const { identificationId, portal } = useAuth();
  useEffect(() => {
    const load = async () => {
      if (identificationId && portal) {
        const response = await GetStaffInformation({
          identificationId,
        });
        setGroups(response.staffGroups);
        setStaffGroupMemberships(response.staffGroupMemberships);
        let list = response.staffGroupMemberships.map((group) =>
          response.staffGroups.find(
            (sgroup) => sgroup.GroupGuid === group.GroupGuid
          )
        );

        const groupedInformation = await GetGroupedContactInformation();

        if (groupedInformation.GetGroupedContactInformationResult) {
          groupedInformation.staffGroupList.forEach((group) => {
            if (!list.some((l) => l.GroupGuid === group.GroupGuid)) {
              list.push({
                ...group,
              });
            }
          });
        }
        /*let newList = [];
        const data = await GetManualStaffGroups();
        if (data) {
            newList = list.map((group) =>
            data.find((sgroup) => sgroup.GroupGuid !== group.GroupGuid)
          );
        }*/
        setStaffGroups(list);
      }
    };
    load();
  }, [identificationId, portal]);
  const reloadGroups = async () => {
    if (identificationId && portal) {
      const response = await GetStaffInformation({
        identificationId,
      });
      setGroups(response.staffGroups);
      let list = response.staffGroupMemberships.map((group) =>
        response.staffGroups.find(
          (sgroup) => sgroup.GroupGuid === group.GroupGuid
        )
      );

      const groupedInformation = await GetGroupedContactInformation();

      if (groupedInformation.GetGroupedContactInformationResult) {
        groupedInformation.staffGroupList.forEach((group) => {
          if (!list.some((l) => l.GroupGuid === group.GroupGuid)) {
            list.push({
              ...group,
            });
          }
        });
      }
      /*const newList = [];
      const data = await GetManualStaffGroups();
      if (data) {
        list.forEach((listItem) => {
          data.forEach((item) => {
            if (listItem.GroupGuid !== item.GroupGuid && listItem !== item) {
              newList.push(item);
            }
          });
        });
      }*/
      setStaffGroups(list);
    }
  };
  return (
    <GroupsContext.Provider
      value={{
        groups,
        staffGroups,
        menuGroups,
        setMenuGroups,
        reloadGroups,
        staffGroupMemberships,
        setStaffGroupMemberships,
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
};

function useGroups() {
  const context = useContext(GroupsContext);
  if (!context) {
    throw new Error('useGroups must be used within a AppProvider');
  }
  return context;
}
export { GroupsProvider, GroupsContext, useGroups };
