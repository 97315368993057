import React, { useCallback, useState } from 'react';
import {
  Sidebar,
  SidebarLeft,
  Container,
  Header,
  IconContainer,
  InfoContainer,
  MembersContainer,
  NameContainer,
  RemoveContainer,
  ArchiveContainer,
} from './styles';
import CloseIcon from '@material-ui/icons/Close';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import { useConversation } from '../../hooks/conversation';
import { useChatMessages } from '../../hooks/chat';
import { FaUser, FaUsers } from 'react-icons/fa';
import { useEffect } from 'react';
import {
  GetStaffInformationForStaffGroup,
  UpdateManualStaffGroup,
} from '../../services/staff';
import Contacts from './contacts';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ConfirmDialog from '../confirmdialog';
import Members from './members';
import { useToast } from '../../hooks/toast';

import { useIntl } from 'react-intl';

const Info = () => {
  const intl = useIntl();
  const {
    openInfo,
    setOpenInfo,
    reloadConversations,
    reloadArchivedConversations,
  } = useConversation();
  const [editName, setEditName] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openArchiveConfirm, setOpenArchiveConfirm] = useState(false);
  const [openUnarchiveConfirm, setOpenUnarchiveConfirm] = useState(false);
  const [openContacts, setOpenContacts] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [name, setName] = useState('');
  const [GroupGuid, setGroupGuid] = useState();
  const [isPrivate, setIsPrivate] = useState(true);
  const { conversation, setConversationArchive, setOpenChat } =
    useChatMessages();
  const [data, setData] = useState();
  const { setSelecetedConversation, deleteConversation, setConversation } =
    useChatMessages();

  const { addToast } = useToast();
  useEffect(() => {
    const init = async () => {
      setGroupGuid(conversation?.Owner?.StaffGuid);
      setEditName(false);
      const response = await GetStaffInformationForStaffGroup({
        staffGroupGuid: conversation?.Owner?.StaffGuid,
      });
      if (response.GetStaffInformationForStaffGroupResult) {
        setData(response.staffGroup);
        setCanEdit(conversation.Administrator);
        setIsPrivate(response.staffGroup.PrivateGroup);
      } else {
        setCanEdit(false);
      }
      setName(conversation?.Owner?.Name);
    };
    if (conversation) init();
  }, [conversation]);
  const reload = async () => {
    setOpenContacts(false);

    setGroupGuid('');
    const response = await GetStaffInformationForStaffGroup({
      staffGroupGuid: conversation?.Owner?.StaffGuid,
    });

    setGroupGuid(conversation?.Owner?.StaffGuid);
    if (response.GetStaffInformationForStaffGroupResult) {
      setData(response.staffGroup);
      setCanEdit(conversation.Administrator);
      setIsPrivate(response.staffGroup.PrivateGroup);
    }
  };
  const editStaffGroup = useCallback(
    async (privateGroup = null) => {
      if (privateGroup !== null) {
        setIsPrivate(privateGroup);
      }
      let sg = data;
      sg.Name = name;
      sg.PrivateGroup = privateGroup !== null ? privateGroup : isPrivate;
      const response = await UpdateManualStaffGroup({
        staffGroup: sg,
      });
      setData(response.staffGroup);
      setSelecetedConversation(
        {
          ...conversation,
          Owner: {
            ...conversation.Owner,
            Name: name,
          },
        },
        true
      );
      setEditName(false);
      setCanEdit(true);
    },
    [name, data]
  );

  const cancelEditStaffGroup = useCallback(() => {
    setName(data.Name);
    setEditName(false);
  }, [name, conversation]);

  const deleteStaffGroup = useCallback(async () => {
    await deleteConversation({ data });
    setOpenInfo(false);
    setOpenConfirm(false);
  }, [name, conversation]);

  const archiveChat = useCallback(async () => {
    const guid = conversation.Guid;

    if (!conversation.IsArchived) {
      await setConversationArchive({ Guid: guid }, true);
      await reloadConversations();
      setOpenInfo(false);
      setOpenArchiveConfirm(false);
      addToast({
        title: intl.formatMessage({ id: 'chatArchived' }),
        type: 'success',
      });
    } else {
      await setConversationArchive({ Guid: guid }, false);
      const cs = await reloadArchivedConversations();
      console.log(cs);
      if (cs && cs.length > 0) {
        setConversation(cs[0]);
        setOpenInfo(false);
        setOpenArchiveConfirm(false);
        addToast({
          title: intl.formatMessage({ id: 'chatUnarchived' }),
          type: 'success',
        });
      } else {
        setOpenChat(false);
        setOpenInfo(false);
        setOpenArchiveConfirm(false);
        addToast({
          title: intl.formatMessage({ id: 'chatUnarchived' }),
          type: 'success',
        });
      }
    }
  }, [name, conversation]);

  return (
    <>
      {conversation?.IsArchived ? (
        <SidebarLeft open={openInfo}>
          <Header open={openInfo}>
            <IconButton onClick={() => setOpenInfo(false)}>
              <CloseIcon />
            </IconButton>
            {conversation?.isGroup
              ? intl.formatMessage({ id: 'GroupData' })
              : intl.formatMessage({ id: 'ContactData' })}
          </Header>
          <Container open={openInfo}>
            <InfoContainer>
              <ArchiveContainer>
                <IconButton
                  onClick={() => setOpenUnarchiveConfirm(true)}
                  title={intl.formatMessage({ id: 'Unarchive' })}
                >
                  <UnarchiveIcon
                    titleAccess={intl.formatMessage({ id: 'Unarchive' })}
                  />
                </IconButton>
              </ArchiveContainer>
              <IconContainer>
                {conversation?.isGroup ? <FaUsers /> : <FaUser />}
              </IconContainer>

              <NameContainer>
                {!canEdit && <span>{name ? name : ''}</span>}
                {canEdit && !editName && <span>{name ? name : ''}</span>}
                {canEdit && editName && (
                  <input
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    type="text"
                  />
                )}
                {canEdit && !editName && conversation && (
                  <EditIcon onClick={() => setEditName(true)} />
                )}
                {canEdit && editName && conversation && (
                  <CheckIcon onClick={() => editStaffGroup()} />
                )}
                {canEdit && editName && conversation && (
                  <CloseIcon onClick={() => cancelEditStaffGroup()} />
                )}
              </NameContainer>
              {canEdit && (
                <FormControlLabel
                  style={{
                    alignSelf: 'flex-start',
                  }}
                  control={
                    <Checkbox
                      style={{ paddingRight: 2 }}
                      size="small"
                      color="secondary"
                      checked={isPrivate}
                      onChange={(e) => editStaffGroup(e.target.checked)}
                    />
                  }
                  label={<label style={{ fontSize: 12 }}>Private</label>}
                />
              )}
              <h6>
                {!canEdit && data && data.PrivateGroup === true
                  ? intl.formatMessage({ id: 'Private' })
                  : null}
              </h6>
              {canEdit && (
                <RemoveContainer>
                  <IconButton onClick={() => setOpenConfirm(true)}>
                    <DeleteIcon />
                  </IconButton>
                </RemoveContainer>
              )}
            </InfoContainer>
            {conversation?.isGroup && (
              <MembersContainer>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{intl.formatMessage({ id: 'Members' })}</span>
                  {canEdit && (
                    <IconButton onClick={() => setOpenContacts(true)}>
                      <AddBoxIcon />
                    </IconButton>
                  )}
                </div>
                <Members
                  GroupGuid={GroupGuid}
                  reload={reload}
                  canEdit={canEdit}
                />
              </MembersContainer>
            )}
          </Container>
          <ConfirmDialog
            handleCancel={() => setOpenConfirm(false)}
            handleOk={deleteStaffGroup}
            question={intl.formatMessage({ id: 'DoYouWantToDeleteThisGroup?' })}
            open={openConfirm}
          />
          <ConfirmDialog
            handleCancel={() => setOpenUnarchiveConfirm(false)}
            handleOk={archiveChat}
            question={intl.formatMessage({
              id: 'WouldYouLikeToUnarchiveThisConversation?',
            })}
            open={openUnarchiveConfirm}
          />
          <Contacts
            open={openContacts}
            GroupGuid={GroupGuid}
            handleClose={() => setOpenContacts(false)}
            reload={reload}
          />
        </SidebarLeft>
      ) : (
        <Sidebar open={openInfo}>
          <Header open={openInfo}>
            <IconButton onClick={() => setOpenInfo(false)}>
              <CloseIcon />
            </IconButton>
            {conversation?.isGroup
              ? intl.formatMessage({ id: 'GroupData' })
              : intl.formatMessage({ id: 'ContactData' })}
          </Header>
          <Container open={openInfo}>
            <InfoContainer>
              <ArchiveContainer>
                <IconButton
                  onClick={() => setOpenArchiveConfirm(true)}
                  title={intl.formatMessage({ id: 'Archive' })}
                >
                  <ArchiveIcon
                    titleAccess={intl.formatMessage({ id: 'Archive' })}
                  />
                </IconButton>
              </ArchiveContainer>
              <IconContainer>
                {conversation?.isGroup ? <FaUsers /> : <FaUser />}
              </IconContainer>

              <NameContainer>
                {!canEdit && <span>{name ? name : ''}</span>}
                {canEdit && !editName && <span>{name ? name : ''}</span>}
                {canEdit && editName && (
                  <input
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    type="text"
                  />
                )}
                {canEdit && !editName && conversation && (
                  <EditIcon onClick={() => setEditName(true)} />
                )}
                {canEdit && editName && conversation && (
                  <CheckIcon onClick={() => editStaffGroup()} />
                )}
                {canEdit && editName && conversation && (
                  <CloseIcon onClick={() => cancelEditStaffGroup()} />
                )}
              </NameContainer>
              {canEdit && (
                <FormControlLabel
                  style={{
                    alignSelf: 'flex-start',
                  }}
                  control={
                    <Checkbox
                      style={{ paddingRight: 2 }}
                      size="small"
                      color="secondary"
                      checked={isPrivate}
                      onChange={(e) => editStaffGroup(e.target.checked)}
                    />
                  }
                  label={<label style={{ fontSize: 12 }}>Private</label>}
                />
              )}
              <h6>
                {!canEdit && data && data.PrivateGroup === true
                  ? intl.formatMessage({ id: 'Private' })
                  : null}
              </h6>
              {canEdit && (
                <RemoveContainer>
                  <IconButton onClick={() => setOpenConfirm(true)}>
                    <DeleteIcon />
                  </IconButton>
                </RemoveContainer>
              )}
            </InfoContainer>
            {conversation?.isGroup && (
              <MembersContainer>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{intl.formatMessage({ id: 'Members' })}</span>
                  {canEdit && (
                    <IconButton onClick={() => setOpenContacts(true)}>
                      <AddBoxIcon />
                    </IconButton>
                  )}
                </div>
                <Members
                  GroupGuid={GroupGuid}
                  reload={reload}
                  canEdit={canEdit}
                />
              </MembersContainer>
            )}
          </Container>
          <ConfirmDialog
            handleCancel={() => setOpenConfirm(false)}
            handleOk={deleteStaffGroup}
            question={intl.formatMessage({ id: 'DoYouWantToDeleteThisGroup?' })}
            open={openConfirm}
          />

          <ConfirmDialog
            handleCancel={() => setOpenArchiveConfirm(false)}
            handleOk={archiveChat}
            question={intl.formatMessage({
              id: 'WouldYouLikeToArchiveThisConversation?',
            })}
            open={openArchiveConfirm}
          />
          <Contacts
            open={openContacts}
            GroupGuid={GroupGuid}
            handleClose={() => setOpenContacts(false)}
            reload={reload}
          />
        </Sidebar>
      )}
    </>
  );
};

export default Info;
