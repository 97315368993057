import React, { useEffect } from 'react';
import {
  FiCheckCircle,
  FiInfo,
  FiAlertCircle,
  FiXCircle,
} from 'react-icons/fi';
import { Content } from './styles';
import { useToast } from '../../../hooks/toast';

const icons = {
  info: <FiInfo size={20} />,
  success: <FiCheckCircle size={20} />,
  error: <FiAlertCircle size={20} />,
};

const Toast = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id]);

  return (
    <Content
      style={style}
      type={message.type}
      hasdescription={message.description ? message.description : ''}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>
      <button type="button" onClick={() => removeToast(message.id)}>
        <FiXCircle size={18} />
      </button>
    </Content>
  );
};
export default Toast;
