import styled from 'styled-components';
export const Body = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 1024px;
  }
`;

export const Header = styled.div`
  height: 70px;
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row !important;
  color: #333;
  align-items: center;
  justify-content: flex-end;
  padding-right: 50px;
  svg {
    color: #333;
    cursor: pointer;
    font-size: 25px;
  }
`;
export const HeaderTitle = styled.div`
  font-size: 18px;
`;
export const Title = styled.div`
  font-size: 18px;
`;
