import { getPortalServerIp } from '../storage';
import api from './apiPortal';
import { IdentificationId } from './util';
// const serverUrl = getPortalServerIp();

const GetConversations = async () => {
  try {
    if (getPortalServerIp() !== 'https://null') {
      api.defaults.baseURL = getPortalServerIp();
      let data = {};
      data = IdentificationId();
      data.conversationFilter = 1;
      return api
        .post('/PortalStaff/ios/GetConversations', data)
        .then((res) => res.data);
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};
const GetConversationsArchive = async () => {
  try {
    if (getPortalServerIp() !== 'https://null') {
      api.defaults.baseURL = getPortalServerIp();
      let data = {};
      data = IdentificationId();
      data.conversationFilter = 2;
      const response = await api
        .post('/PortalStaff/ios/GetConversations', data)
        .then((res) => res.data);
      return response;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

const GetMessagesForConversations = async ({
  Guid,
  indexMessageGuid = '',
  maxNumberMessages = -1,
}) => {
  if (getPortalServerIp() !== 'https://null') {
    api.defaults.baseURL = getPortalServerIp();
    let identificationId = IdentificationId();
    identificationId.conversationGuid = Guid;
    identificationId.indexMessageGuid = indexMessageGuid;
    identificationId.maxNumberMessages = maxNumberMessages;
    // console.log(identificationId);
    return api
      .post('/PortalStaff/ios/GetMessagesForConversation', identificationId)
      .then((res) => res.data);
  } else {
    return [];
  }
};

const GetOrCreateConversationByContactInformation = async ({
  contactInformation,
}) => {
  api.defaults.baseURL = getPortalServerIp();
  let body = {};
  try {
    body = IdentificationId();
    body.contactInformation = contactInformation;
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/GetOrCreateConversationByContactInformation', body)
    .then((res) => res.data);
};

const CreateManualStaffGroup = async ({ name, priority, privateGroup }) => {
  let body = {};
  api.defaults.baseURL = getPortalServerIp();
  try {
    body = IdentificationId();
    body.name = name;
    body.priority = priority;
    body.privateGroup = privateGroup;
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/CreateManualStaffGroup', body)
    .then((res) => res.data);
};
const AddMembersToManualStaffGroup = async ({
  staffGroup,
  toBeIncludedMembers,
}) => {
  api.defaults.baseURL = getPortalServerIp();
  let body = {};
  try {
    body = IdentificationId();
    body.staffGroup = staffGroup;
    body.toBeIncludedMembers = toBeIncludedMembers;
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/AddMembersToManualStaffGroup', body)
    .then((res) => res.data);
};
const RemoveMembersFromManualStaffGroup = async ({
  staffGroup,
  toBeExcludedMembers,
}) => {
  api.defaults.baseURL = getPortalServerIp();
  let body = {};
  try {
    body = IdentificationId();
    body.staffGroup = staffGroup;
    body.toBeExcludedMembers = toBeExcludedMembers;
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/RemoveMembersFromManualStaffGroup', body)
    .then((res) => res.data);
};

const DeleteConversation = async ({ Guid }) => {
  api.defaults.baseURL = getPortalServerIp();
  let body = {};
  try {
    body = IdentificationId();
    body.conversation = { Guid };
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/DeleteConversation', body)
    .then((res) => res.data);
};
const DeleteManualStaffGroup = async ({ staffGroup }) => {
  let body = {};
  api.defaults.baseURL = getPortalServerIp();
  try {
    body = IdentificationId();
    body.staffGroup = staffGroup;
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/DeleteManualStaffGroup', body)
    .then((res) => res.data);
};

const setConversationState = async ({ Guid }, isArchive) => {
  let body = {};
  api.defaults.baseURL = getPortalServerIp();
  try {
    body = IdentificationId();
    body.conversation = Guid;
    body.isArchived = isArchive;
  } catch (error) {
    console.log(error);
  }
  return api
    .post('/PortalStaff/ios/SetConversationState', body)
    .then((res) => console.log(res.data));
};

const setCallStatus = async (callerID) => {
  try {
    if (getPortalServerIp() !== 'https://null') {
      // api.defaults.baseURL = 'https://portal-test.squre.io'; // production
      // api.defaults.baseURL = serverUrl; // development

      api.defaults.baseURL = getPortalServerIp();
      let data = {};
      data = IdentificationId();
      data.callGuid = callerID;
      data.videoEnabled = false;
      // console
      console.log(data);
      return api
        .post('/portalcall/ios/setvideostatus', data)
        .then((res) => res.data);
      // .then((res) => console.log(res))
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

const getCallEvent = async () => {
  try {
    if (getPortalServerIp() !== 'https://null') {
      // api.defaults.baseURL = 'https://portal-test.squre.io'; // production
      // api.defaults.baseURL = 'https://squre-stage.squre.io'; // staging
      api.defaults.baseURL = getPortalServerIp();
      let data = {};
      data = IdentificationId();
      data.maxTimeOutSeconds = 10;
      // console
      // console.log(data);
      return api
        .post('/portalcall/ios/GetCallEvent', data)
        .then((res) => res.data);
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

const GetCallStatus = async (callGuid) => {
  try {
    if (getPortalServerIp() !== 'https://null') {
      // api.defaults.baseURL = 'https://portal-test.squre.io'; // production
      // api.defaults.baseURL = 'https://squre-stage.squre.io'; // staging

      api.defaults.baseURL = getPortalServerIp();
      let data = {};
      data = IdentificationId();
      data.callGuid = callGuid;
      data.maxTimeOutSeconds = 0;
      // console
      // console.log(data);
      return api
        .post('/portalcall/ios/getcallstatus', data)
        .then((res) => res.data);
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

const StartCallUsingContactInformation = async (staffGuid, identityType) => {
  try {
    if (getPortalServerIp() !== 'https://null') {
      // api.defaults.baseURL = 'https://portal-test.squre.io'; // production
      // api.defaults.baseURL = 'https://squre-stage.squre.io'; // staging

      api.defaults.baseURL = getPortalServerIp();
      let data = {};
      data = IdentificationId();
      let identityInformationData = {
        IdentityGuid: staffGuid, // staffGuid
        IdentityType: identityType, //1 (for staffmember), 3 (for staffmembergroup)
      };

      data.identityInformation = identityInformationData;
      data.priority = 0;
      return api
        .post('/portalcall/ios/StartCallUsingContactInformation', data)
        .then((res) => res.data);
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

const GetCallInformationUsingCallGuid = async (callGuid) => {
  try {
    if (getPortalServerIp() !== 'https://null') {
      // api.defaults.baseURL = 'https://portal-test.squre.io'; // production
      // api.defaults.baseURL = 'https://squre-stage.squre.io'; // staging

      api.defaults.baseURL = getPortalServerIp();
      let data = {};
      data = IdentificationId();
      data.callGuid = callGuid;
      return api
        .post('/portalcall/ios/GetCallInformationUsingCallGuid', data)
        .then((res) => res.data);
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

const callDisconnectUsingGuid = async (callGuid) => {
  try {
    if (getPortalServerIp() !== 'https://null') {
      // api.defaults.baseURL = 'https://portal-test.squre.io'; // production
      // api.defaults.baseURL = 'https://squre-stage.squre.io'; // staging

      api.defaults.baseURL = getPortalServerIp();
      let data = {};
      data = IdentificationId();
      data.callGuid = callGuid;
      return api
        .post('/portalcall/ios/CallCancelled', data)
        .then((res) => res.data);
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

const GetCallHistory = async () => {
  try {
    if (getPortalServerIp() !== 'https://null') {
      // api.defaults.baseURL = 'https://portal-test.squre.io'; // production
      // api.defaults.baseURL = 'https://squre-stage.squre.io'; // staging

      api.defaults.baseURL = getPortalServerIp();
      let data = {};
      data = IdentificationId();
      // data.callGuid = callGuid;
      return api
        .post('/portalcall/ios/GetCallHistory', data)
        .then((res) => res.data);
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

export {
  GetConversations,
  GetConversationsArchive,
  GetMessagesForConversations,
  GetOrCreateConversationByContactInformation,
  CreateManualStaffGroup,
  DeleteManualStaffGroup,
  AddMembersToManualStaffGroup,
  RemoveMembersFromManualStaffGroup,
  DeleteConversation,
  setConversationState,
  setCallStatus,
  getCallEvent,
  GetCallStatus,
  StartCallUsingContactInformation,
  GetCallInformationUsingCallGuid,
  callDisconnectUsingGuid,
  GetCallHistory,
};
