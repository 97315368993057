import {
  RetrievePushApplicationPublicKey,
  RegisterWebPushNotificationId,
} from './services/pushNotification';

async function subscribeUserToPush() {
  try {
    const registration = await navigator.serviceWorker.register(
      '/serviceWorker.js'
    );
    const applicationServerPublicKey = await RetrievePushApplicationPublicKey();
    let subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(applicationServerPublicKey),
    };
    requestPermission();
    const pushSubscription = await registration.pushManager.subscribe(
      subscribeOptions
    );
    const data = JSON.parse(JSON.stringify(pushSubscription));
    RegisterWebPushNotificationId(data);
    return await pushSubscription;
  } catch (error) {
    return;
  }
}

export async function requestPermission() {
  const permissionResult_1 = await new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
  //console.log(permissionResult);
  if (permissionResult_1 !== 'granted') {
    throw new Error('Permission not granted.');
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default subscribeUserToPush;
