import React, { createContext, useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import { Button, IconButton, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useConversation } from '../../hooks/conversation';
import './style.css';
import { useChatMessages } from '../../hooks/chat';
import {
  // getCallEvent,
  // GetCallInformationUsingCallGuid,
  StartCallUsingContactInformation,
} from '../../services/conversations';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const MenuConversation = ({ id }) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { setOpenInfo } = useConversation();
  const [canJoin, setCanJoin] = React.useState(false);
  // const [callEvents, setCallEvents] = React.useState(null);
  const [callerData, setCallerData] = React.useState(null);
  // get data for selected conversation
  const { conversation, selectedPage, setSelectedPage } = useChatMessages();

  const intl = useIntl();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const makeCall = () => {
    // set call to 0 => call is in pending state
    setCanJoin(0);

    // set a  timeout to check if call is ready
    setTimeout(() => {
      const makeCall = StartCallUsingContactInformation(
        conversation?.Owner?.StaffGuid, // callerID
        conversation?.Owner?.StaffGuid === conversation?.Owner?.Number ? 3 : 1 // 1 (for staffmember) 3 (for staffmembergroup)
      );
      // promise fullfilled => call is in progress
      Promise.resolve(makeCall).then((data) => {
        if (data?.StartCallUsingContactInformationResult === true) {
          // set call to 1 => call is in progress
          setCanJoin(true);
          // set caller data
          setCallerData(data?.portalCallContactInformation);

          // navigate to call page
          history.push({
            pathname: '/jitsi/' + data?.portalCallContactInformation?.CallGuid,
            state: data?.portalCallContactInformation,
          });
        } else {
          // console.log('no call');
          // set call to 2 => call is not possible
          setCanJoin(false);
        }
      });
    }, 2000);
  };

  useEffect(() => {
    if (conversation) {
      setSelectedPage(true);
    }
    if (selectedPage) {
      console.log(selectedPage);
    }
  }, [conversation, selectedPage]);

  // useEffect(() => {
  //   setInterval(() => {
  //     const data = getCallEvent();

  //     Promise.resolve(data).then((data) => {
  //       if (data?.GetCallEventResult === true) {
  //         setCallEvents(data?.callEvent);
  //         // console.log('call event', data?.callEvent);
  //         let responseDataCaller = GetCallInformationUsingCallGuid(
  //           data?.callEvent?.CallGuid
  //         );
  //         Promise.resolve(responseDataCaller).then((data) => {
  //           if (data) {
  //             console.log('responseDataCaller', data);
  //           }
  //         });
  //         // confirm call
  //         Swal.fire({
  //           title: 'Call from ' + data?.callEvent?.ContactInformation?.Name,
  //           text: 'Do you want to accept the call?',
  //           icon: 'question',
  //           showCancelButton: true,
  //           confirmButtonColor: '#3085d6',
  //           cancelButtonColor: '#d33',
  //           confirmButtonText: 'Yes',
  //           cancelButtonText: 'No',
  //         }).then((result) => {
  //           if (result.value) {
  //             // redirect to call page
  //             history.push({
  //               pathname: '/jitsi/' + data?.callEvent?.CallGuid,
  //               state: callEvents,
  //             });
  //           } else {
  //             console.log('false');
  //           }
  //         });
  //       }
  //     });
  //   }, 5000);
  // }, []);

  return (
    <React.Fragment>
      {/* make call starts*/}
      {canJoin === true ? (
        <Link
          onClick={makeCall}
          to={{
            pathname: '/jitsi/' + callerData?.CallGuid,
            state: {
              callerData,
              //  conversation
            },
          }}
          className="callBtn btn-success btn-sm"
        >
          Join Call
        </Link>
      ) : canJoin === 0 ? (
        <span className="callBtn">Connecting...</span>
      ) : (
        <Link to="#" className="callBtn" onClick={makeCall}>
          Make Call
        </Link>
      )}
      {/* Make call ends */}
      <IconButton
        style={{
          position: 'absolute',
          right: 0,
        }}
        aria-controls={id}
        aria-haspopup="true"
        onClick={() => {
          setOpenInfo(true);
          handleClose();
        }}
        size="medium"
      >
        <MoreVertIcon size="small" color="inherit" />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setOpenInfo(true);
            handleClose();
          }}
        >
          {intl.formatMessage({ id: 'Info' })}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default MenuConversation;
