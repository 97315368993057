import styled, { css } from 'styled-components';
import theme from '../../styles/material';
import background from '../../assets/images/chat2.jpeg';
import { IconButton } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
export const Input = styled(InputBase)`
  width: 100%;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
`;
export const InputContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #f0f0f0;
  background-color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  Input {
    border: none;
    background-color: transparent;
    flex: 1;
  }
  img {
    height: 24px;
    width: 24px;
  }
`;

export const Container = styled.div`
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  right: 0;
  bottom: 0;
  top: 60px;
  left: 350px;
  padding: 30px;
  padding-top: 0px;
  background-color: #f0f0f0;
`;
export const Content = styled.div`
  overflow-y: auto;
  padding: 30px;
  padding-top: 0px;
  padding-bottom: ${(props) => props.height + 'px'};
  background-color: #f0f0f0;
  /* background: url(${background}); */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex: 1;
  /* ${(props) =>
    props.isMessageSelected === true &&
    css`
      padding-bottom: 130px;
    `};
  ${(props) =>
    props.isEmojisOpened === true &&
    css`
      padding-bottom: 390px;
    `};
    */
`;

export const SendContent = styled.form`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #e0e0e0;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  width: 100%;
  svg {
    color: ${theme.palette.secondary.main};
    font-size: 26px;
    cursor: pointer;
  }
  button {
    border: none;
    background-color: transparent;
  }
  label {
    input {
      display: none;
    }
  }
`;

export const BottomContent = styled.div`
  position: absolute;
  overflow-y: auto;
  right: 0;
  bottom: 0;
  left: 350px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #e0e0e0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px;
`;
export const ButtonClose = styled(IconButton)``;
export const ButtonScrollDown = styled.div`
  position: absolute;
  bottom: 60px;
  right: 10px;
  background-color: #039be5 !important;
  color: #fff !important;
  height: 36px;
  width: 36px;
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Unread = styled.div`
  padding: 8px;
  background: #e0e0e0;
  border-radius: 10px;
  color: #999;
  text-align: center;
`;
export const RepliedMessage = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  border-left: 2px solid #666;
  text-align: left;
  min-height: 30px;
  margin-bottom: 10px;
`;
export const MessageContent = styled.div`
  background-color: #e0e0e0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  padding-bottom: 0px;
  z-index: 100;
  margin-left: 90px;
`;

export const ForwardText = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  top: 2px;
  left: 2px;
  font-size: 12px;
  color: #999;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  margin-bottom: 5px;
`;

export const MessageMarked = styled.div`
  background: #f0f0f0;
  position: relative;
  padding-top: 25px;
  padding-right: 25px;
  padding-left: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  font-size: 14px;
  border-radius: 10px;
  ${ButtonClose} {
    position: absolute;
    top: -10px;
    right: -10px;
  }
`;
export const BalloonDate = styled.div`
  position: absolute;
  right: 20px;
  bottom: 6px;
  padding: 2px;
  font-size: 11px;
`;
export const BalloonActions = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
  color: #999;
  transition: visibility 0.1ms;
`;

export const BalloonInfo = styled.div`
  position: absolute;
  right: 4px;
  bottom: 0px;
  svg {
    width: 15px;
  }
`;

export const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  align-items: center;
  justify-content: center;

  hr {
    border: none;
    height: 1px;
    background: linear-gradient(to right, #dee2e6, #1a1c1e, #dee2e6);
    margin: 0.8rem 0;
    width: 90%;
  }

  hr::before {
    content: '';
    display: block;
    height: 1px;
    margin-top: -1px;
    background: linear-gradient(to right, #fff, #1a1c1e, #fff);
  }
`;

export const AttachmentFile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: start;
  justify-content: left;
  padding: 5px;

  img {
    height: 120px;
    width: auto; /* Ensure image maintains aspect ratio */
    cursor: pointer;
    margin: 0 auto; /* Center horizontally */
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    max-width: 480px;
    p {
      margin-left: 5px;
      font-family: arial;
      font-weight: 500;
      color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
      text-align: left;
    }
  }
`;

export const Balloon = styled.div`
  min-width: 130px;
  max-width: 500px;
  margin: 10px;
  border-radius: 5px;
  padding: 20px 10px;
  text-align: left;
  font-weight: 500;
  font-family: arial;
  position: relative;

  padding-bottom: 30px;
  margin-bottom: 20px;

  ${BalloonActions} {
    visibility: hidden;
  }

  ${(props) =>
    props.isDeleted &&
    css`
      padding-bottom: 15px;
    `}

  ${(props) =>
    props.isToday &&
    css`
      min-width: 55px;
    `}

    ${(props) =>
      props.isForward &&
      props.isToday &&
      css`
        min-width: 100px !important;
        background-color: red;
        padding-top: 5px;
        padding-left: 10px;
      `}

      ${(props) =>
        props.isForward &&
        css`
          min-width: 100px !important;
          background-color: red;
          padding-top: 5px;
          padding-left: 10px;
        `}

  ${(props) =>
    props.my === false &&
    css`
    text-align:left;
    align-self:flex-start;
    box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);
    background-color:#fff;
    color: #000 !important;
  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid transparent;
    left: -10px;
    top: 6px;
  `}

  ${(props) =>
    props.my === true &&
    css`
    text-align:right;
    align-self:flex-end;
    box-shadow: -2px 2px 3px 1px rgba(0, 0, 0, 0.2);
    background-color: #f9fbe7;
    color:#333;
  &:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid #f9fbe7;
    border-right: 10px solid transparent;
    border-top: 10px solid #f9fbe7;
    border-bottom: 10px solid transparent;
    right: -10px;
    top: 6px;
  `}
  }
  &:hover {
    ${BalloonActions} {
      visibility:visible;
    }
`;
