import React, { useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ArrowDropDownOutlined } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { RemoveMessage } from '../../services/chat';

import { useIntl } from 'react-intl';

const ActionsMessage = ({
  my,
  message,
  setSelectedMessage,
  setSelectedMessageForward,
}) => {
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setAction = useCallback(
    async ({ action }) => {
      if (action === 'delete') {
        const result = await RemoveMessage({
          staffMessage: message,
        });
        if (result.repliedMessage) {
          setSelectedMessage();
        }
      }
      if (action === 'forward') {
        setSelectedMessageForward(message);
      }

      if (action === 'reply') {
        setSelectedMessage({
          action,
          message,
        });
      }
      handleClose();
    },
    [message, setSelectedMessage, setSelectedMessageForward]
  );

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <ArrowDropDownOutlined style={{ fontSize: 24 }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {my === false && (
          <MenuItem onClick={() => setAction({ action: 'reply' })}>
            {intl.formatMessage({ id: 'Reply' })}
          </MenuItem>
        )}
        {message.IsPoll === false && (
          <MenuItem onClick={() => setAction({ action: 'forward' })}>
            {intl.formatMessage({ id: 'Forward' })}
          </MenuItem>
        )}
        {my && (
          <MenuItem onClick={() => setAction({ action: 'delete' })}>
            {intl.formatMessage({ id: 'Delete' })}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default ActionsMessage;
