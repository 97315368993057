import styled, { css } from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import LinearProgress from '@material-ui/core/LinearProgress';

export const Input = styled(InputBase)`
  width: 100%;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
`;

export const PollTitleStyle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 5px;
`;

export const PollSubtitle = styled.div`
  font-size: 15px;
  margin-top: 10px;
`;

export const PollOptionStyle = styled.div`
  margin-left: 5px;
  // margin: 10px;
  // padding: 10px;
  font-size: 20px;

  .pollOptionContainer {
    // border: 1px solid black;
  }

  .text {
    display: flex;
    justify-content: space-between; /* Aligns items to each end of the container */
    align-items: center; /* Aligns items vertically center */
    margin-top: 10px;
    // border: 1px solid black;
  }

  label {
    flex-grow: 1;
  }

  .progressbar {
    padding: 5px;
    // border: 1px solid black;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  && {
    .MuiIconButton-root {
      padding: 0;
    }
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }
`;
export const CustomRadioButton = styled(Radio)`
  && {
    .MuiIconButton-root {
      padding: 0;
    }
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  && {
    height: 4px;
  }
`;
