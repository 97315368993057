import * as React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import {
  StyledDialogTitle,
  StyledIconButton,
  StyledDialogActions,
  QuestionHeading,
  StyledDialogContent,
  StyledSwitchContainer,
  StyledSwitchLabel,
  StyledSwitch,
  StyledInput,
  SendButton,
} from './styles';

import { Dialog, Input } from '@material-ui/core';
import { useIntl } from 'react-intl';

const ariaLabel = { 'aria-label': 'description' };
const MAX_INPUTS = 5;

class PollOptionModel {
  constructor(data) {
    this.id = data.id || null;
    this.text = data.text || '';
    this.isSelectedBy = data.isSelectedBy || [];
  }
}

class PollMessageModel {
  constructor(data) {
    this.PollTitle = data.PollTitle || '';
    this.PollOptions = (data.PollOptions || []).map(
      (optionData) => new PollOptionModel(optionData)
    );
    this.PollReplay = data.PollReplay || [];
    this.multiSelect = data.multiSelect || false;
  }
}

const CreatePollDialog = ({ open, handleCancel, handleOk }) => {
  const [newOptions, setNewOptions] = React.useState([
    { id: 1, text: '' },
    { id: 2, text: '' },
  ]);
  const [isMultiSelect, setChecked] = React.useState(false); // State for switch
  const [pollTitle, setPollTitle] = React.useState(''); // State for poll title
  const [pollTitleError, setPollTitleError] = React.useState('');
  const [pollOptionError, setPollOptionError] = React.useState('');
  const intl = useIntl();

  const addItem = () => {
    const newData = [...newOptions, { id: newOptions.length + 1, text: '' }];
    setNewOptions(newData);
  };

  const handlePollTitleChange = (event) => {
    setPollTitle(event.target.value);
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const onChangeText = (text, index) => {
    const data = [...newOptions];
    data[index].text = text;
    setNewOptions(data);

    const allFieldsFilled = data.every((item) => item.text?.trim().length > 0);
    if (
      allFieldsFilled &&
      newOptions.length < MAX_INPUTS &&
      newOptions[index].text?.trim().length > 0
    ) {
      addItem();
    }
  };

  const save = () => {
    setPollTitleError('');
    setPollOptionError('');

    if (!pollTitle.trim()) {
      setPollTitleError(intl.formatMessage({ id: 'ThisFieldIsRequired' }));
      return;
    }

    if (newOptions.filter((input) => input.text.trim() !== '').length < 2) {
      setPollOptionError('Please provide at least two options for the poll.');
      return;
    }

    const nonEmptyInputs = newOptions.filter(
      (option) => option.text.trim() !== ''
    );
    const newPollOptions = nonEmptyInputs.map(
      (option, index) =>
        new PollOptionModel({ id: index + 1, text: option.text })
    );
    const newPollMessage = new PollMessageModel({});
    newPollMessage.PollOptions = newPollOptions;
    newPollMessage.PollTitle = pollTitle;
    newPollMessage.multiSelect = isMultiSelect;
    let Message = JSON.stringify(newPollMessage);

    handleOk(Message);
    cancel();
  };

  const cancel = () => {
    setNewOptions([
      { id: 1, text: '' },
      { id: 2, text: '' },
    ]);
    setPollTitle('');
    setChecked(false);
    setPollTitleError('');
    setPollOptionError('');
    handleCancel();
  };

  const onBlurTextInput = (index) => {
    if (newOptions.length > 2) {
      const filteredData = newOptions.filter(
        (item, idx) => idx !== index || item.text?.trim().length > 0
      );
      const updateDataIds = filteredData.map((item, idx) => ({
        ...item,
        id: idx + 1,
      }));

      setNewOptions(updateDataIds);
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <StyledDialogTitle>
        <StyledIconButton aria-label="close" onClick={cancel}>
          <CloseIcon />
        </StyledIconButton>
        {intl.formatMessage({ id: 'CreatePoll' })}
      </StyledDialogTitle>
      <StyledDialogContent>
        <div>
          <QuestionHeading> Question </QuestionHeading>
          <Input
            fullWidth
            autoFocus
            placeholder="Ask question"
            inputProps={ariaLabel}
            value={pollTitle}
            onChange={handlePollTitleChange}
          />
          {pollTitleError && (
            <div style={{ color: 'red', fontSize: 11, padding: 5 }}>
              {pollTitleError}
            </div>
          )}
          <QuestionHeading> Options </QuestionHeading>
          {newOptions.map((value, index) => (
            <div key={index}>
              <StyledInput
                fullWidth
                placeholder="Add"
                value={value.text}
                onChange={(e) => onChangeText(e.target.value, index)}
                onBlur={() => onBlurTextInput(index)}
                inputProps={ariaLabel}
              />
            </div>
          ))}
          {pollOptionError && (
            <div style={{ color: 'red', fontSize: 11, padding: 5 }}>
              {pollOptionError}
            </div>
          )}
        </div>
        <StyledSwitchContainer>
          <StyledSwitchLabel>Allow multiple answers</StyledSwitchLabel>
          <StyledSwitch
            checked={isMultiSelect}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </StyledSwitchContainer>
      </StyledDialogContent>
      <div>
        <SendButton onClick={save}>
          <SendIcon />
        </SendButton>
      </div>
      <StyledDialogActions></StyledDialogActions>
    </Dialog>
  );
};

export default CreatePollDialog;
