import React, { useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Button, DialogContentText } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  StyledDialogTitle,
  StyledIconButton,
  StyledDialogActions,
  StyledDialogContent,
  CustomeTextField,
  CustomDialog,
} from './styles';
import { UpdatePassword } from '../../../services/auth';
import { useAuth } from '../../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

const ChangePasswordDialog = ({ open, handleCancel }) => {
  const intl = useIntl();
  const { user, portal, setPortal } = useAuth();
  const history = useHistory();
  const { addToast } = useToast(false);

  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [oldPasswordError, setOldPasswordError] = React.useState('');
  const [newPasswordError, setNewPasswordError] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
  const [passwordRules, setPasswordRules] = React.useState(
    intl.formatMessage({ id: 'passwordRules' })
  );

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    setPortal(null);
    history.push('/');
  }, [history, setPortal]);

  const validatePassword = (password) => {
    const minLength = 12;
    const uppercase = /[A-Z]/;
    const lowercase = /[a-z]/;
    const number = /[0-9]/;
    const specialChar = /[^A-Za-z0-9\s]/;
    const invalidChar = /[\\/]/;

    if (password) {
      if (password.length < minLength) {
        return intl.formatMessage({ id: 'PasswordTooShort' });
      }
      if (!uppercase.test(password)) {
        return intl.formatMessage({ id: 'PasswordUppercaseRequired' });
      }
      if (!lowercase.test(password)) {
        return intl.formatMessage({ id: 'PasswordLowercaseRequired' });
      }
      if (!number.test(password)) {
        return intl.formatMessage({ id: 'PasswordNumberRequired' });
      }
      if (!specialChar.test(password)) {
        return intl.formatMessage({ id: 'PasswordSpecialCharRequired' });
      }
      if (invalidChar.test(password)) {
        return intl.formatMessage({ id: 'PasswordInvalidChar' });
      }
      return '';
    } else {
      return intl.formatMessage({ id: 'NewPasswordRequired' });
    }
  };

  const handleSave = async () => {
    let hasError = false;

    if (!oldPassword) {
      setOldPasswordError(intl.formatMessage({ id: 'OldPasswordRequired' }));
      hasError = true;
    } else {
      setOldPasswordError('');
    }

    const newPasswordError = validatePassword(newPassword);
    if (newPasswordError) {
      setNewPasswordError(newPasswordError);
      hasError = true;
    } else {
      setNewPasswordError('');
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(
        intl.formatMessage({ id: 'PasswordsDoNotMatch' })
      );
      hasError = true;
    } else {
      setConfirmPasswordError('');
    }

    if (!hasError) {
      const result = await UpdatePassword({
        oldPassword,
        newPassword,
      });
      if (result.SetPasswordResult) {
        addToast({
          title: intl.formatMessage({ id: 'passwordUpdateNotification' }),
          type: 'success',
        });
        cancel();
        logout();
      } else {
        addToast({
          title: intl.formatMessage({ id: 'passwordUpdateError' }),
          type: 'error',
        });
      }
    }
  };

  const handleOldPasswordBlur = () => {
    if (!oldPassword) {
      setOldPasswordError(intl.formatMessage({ id: 'OldPasswordRequired' }));
    } else {
      setOldPasswordError('');
    }
  };
  const handleNewPasswordBlur = () => {
    const error = validatePassword(newPassword);
    setNewPasswordError(error);
  };
  const handleConfirmPasswordBlur = () => {
    if (confirmPassword && newPassword !== confirmPassword) {
      setConfirmPasswordError(
        intl.formatMessage({ id: 'PasswordsDoNotMatch' })
      );
    } else {
      setConfirmPasswordError('');
    }
  };

  const cancel = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setOldPasswordError('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    handleCancel();
  };

  return (
    <CustomDialog open={open} maxWidth="sm" fullWidth={true}>
      <StyledDialogTitle>
        <StyledIconButton aria-label="close" onClick={cancel}>
          <CloseIcon />
        </StyledIconButton>
        {intl.formatMessage({ id: 'ChangePassword' })}
      </StyledDialogTitle>
      <StyledDialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{ __html: passwordRules }}
        />
        <CustomeTextField
          id="old-password"
          fullWidth
          label={intl.formatMessage({ id: 'OldPassword' })}
          value={oldPassword}
          color="secondary"
          error={Boolean(oldPasswordError)}
          helperText={oldPasswordError}
          onChange={(e) => setOldPassword(e.target.value)}
          onBlur={handleOldPasswordBlur}
        />
        <CustomeTextField
          id="new-password"
          fullWidth
          label={intl.formatMessage({ id: 'NewPassword' })}
          value={newPassword}
          color="secondary"
          error={Boolean(newPasswordError)}
          helperText={newPasswordError}
          onChange={(e) => setNewPassword(e.target.value)}
          onBlur={handleNewPasswordBlur}
        />
        <CustomeTextField
          id="confirm-password"
          fullWidth
          label={intl.formatMessage({ id: 'ConfirmNewPassword' })}
          value={confirmPassword}
          color="secondary"
          error={Boolean(confirmPasswordError)}
          helperText={confirmPasswordError}
          onChange={(e) => setConfirmPassword(e.target.value)}
          onBlur={handleConfirmPasswordBlur}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={cancel}>
          {intl.formatMessage({ id: 'Cancel' })}
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleSave}>
          {intl.formatMessage({ id: 'ChangePassword' })}
        </Button>
      </StyledDialogActions>
    </CustomDialog>
  );
};

export default ChangePasswordDialog;
