import styled, { css } from 'styled-components';
import theme from '../../styles/material';
import { shade } from 'polished';

export const Content = styled.div`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  flex: 1;
  color: #333;
  width: 100%;
  & > input {
    position: absolute;
    top: 61px;
    height: 38px;
    border: none;
    z-index: 10;
    padding: 10px 20px;
  }
`;

export const Header = styled.div`
  background-color: #f9f9f9;
  border-left: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #333;
  z-index: 11;
  padding: 0 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 425px;
  min-width: 425px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
`;

export const SearchArea = styled.div`
  position: absolute;
  top: 61px;
  height: 38px;
  min-width: 425px;
  border: none;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;

  svg {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    margin: 0 15px;
    cursor: pointer;
  }
`;

export const SearchInput = styled.input`
  /*position: absolute;
  top: 61px;
  height: 38px;*/
  min-width: 375px;
  border: none;
  z-index: 10;
  padding: 10px 20px;
  display: inline;
  &:active,
  &:focus {
    background: #c6def0;
  }
`;

export const HeaderChat = styled.div`
  border-right: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  background-color: #f9f9f9;
  color: #666;
  padding: 5px;
  padding-left: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: grid;

  grid-template-columns: 1fr 1fr;
  position: absolute;
  top: 0;
  left: 351px;
  right: 0;
  height: 60px;

  div {
    display: flex;
    span {
      font-size: 11px;
      color: #666;
    }
  }
`;
export const Profile = styled.div`
  font-size: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  column-gap: 5px;
`;
export const ContactContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding-left: 5px;
  height: 30px;
`;

export const Reads = styled.div`
  position: absolute;
  top: 10px;
  right: 5px;
  height: 16px;
  width: 16px;
  padding: 5px;
  border-radius: 100%;
  background-color: #039be5;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  place-content: center;
  place-items: center;
`;

export const ButtonsContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  color: #666;
  padding-top: 3px;
  svg {
    cursor: pointer;
    margin: 5px;
    &:hover {
      color: #039be5;
    }
  }
`;

export const ButtonsContainerMenu = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  color: #666;
  height: 100%;

  svg {
    cursor: pointer;
    margin: 5px;
    &:hover {
      color: #039be5;
    }
  }
`;

export const ItensContainerMenu = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${shade(0.1, '#f9f9f9')};
  }
  ${(props) =>
    props.isSelected === true &&
    css`
      background-color: ${shade(0.1, '#f9f9f9')};
    `}
`;

export const ConversationsContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 100px;
  z-index: 10;
  width: 425px;
  min-width: 425px;
  max-width: 100%;
  background: #fff;
  color: ${theme.palette.primary.main};
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid #f0f0f0;
  overflow-y: scroll;
  overflow-x: hidden;
`;
export const ConversationItem = styled.div`
  position: relative;
  background-color: #fff;
  padding: 15px;
  border-top: 2px solid #f9f9f9;
  border-bottom: 2px solid #f9f9f9;
  transition: background-color 0.8s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  + div {
    border-top: none;
  }
  cursor: pointer;
  ${(props) =>
    props.isSelected === true &&
    css`
      background-color: ${shade(0.1, '#f9f9f9')};
    `}

  transition: filter .2s;

  &:hover {
    ${(props) =>
      props.isSelected === undefined &&
      css`
        filter: brightness(0.95);
      `}
    ${(props) =>
      props.isSelected === false &&
      css`
        filter: brightness(0.95);
      `}
  }
`;

export const DateTimeMessage = styled.h4`
  position: absolute;
  top: 12px;
  right: 25px;
  font-size: 10px;
  max-width: 130px;
  ${(props) =>
    !props.unreadLabel &&
    css`
      right: 10px;
    `}
`;
