import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import { Body, Header, HeaderTitle } from './styles';
import { getIdentificationId, getPortalServerIp } from '../../../storage';
const DialogImage = ({ att, open, close }) => {
  const urlDowload = `${getPortalServerIp()}/CareServantAttachments/AttachmentManagerService.svc/DownloadAttachment/${getIdentificationId()}`;

  return (
    <Dialog fullScreen maxWidth="lg" open={open} onClose={close}>
      <Header>
        <CloseIcon onClick={close} />
      </Header>
      <DialogContent>
        <Body>
          {att && (att.AttachmentType === 0 || att.AttachmentType === 1) && (
            <img src={`${urlDowload}/${att.Guid}`} />
          )}
        </Body>
      </DialogContent>
    </Dialog>
  );
};

export default DialogImage;
