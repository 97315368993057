import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import {
  Switch,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Input,
  InputLabel,
  Button,
  Dialog,
} from '@material-ui/core';

export const StyledDialogTitle = styled(DialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #039be5;
    color: white;
    padding-right: 16px;
    padding-left: 10px;
    height: 50px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    margin-right: 15px;
    margin-bottom: 4px;
    svg {
      color: white;
    }
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  && {
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    padding-bottom: 15px;
    padding-right: 20px;
  }
`;

export const SendButton = styled(Button)`
  && {
    min-height: 56px;
    min-width: 60px;
    background-color: #e9edef;
    border-radius: 50%;
    bottom: 25px;
    right: 25px;
    position: absolute;
    padding-left: 10px;
    svg {
      color: #bbc5cb;
    }
    &:hover {
      background-color: #039be5;
      svg {
        color: #fff;
      }
    }
  }
`;

export const QuestionHeading = styled.h4`
  margin: 30px 0px 15px 0px;
  font-weight: bold;
`;

export const StyledSwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px 90px 0px;
`;

export const StyledSwitchLabel = styled(InputLabel)`
  flex: 1;
`;

export const StyledSwitch = styled(Switch)`
  flex-shrink: 0;
  padding-right: 0px;
`;

export const StyledInput = styled(Input)`
  && {
    margin: 10px; /* Add margin to the bottom */
  }
`;

export const CustomeTextField = styled(TextField)`
  && {
    margin-bottom: 30px;
  }
`;

// Custom styled Dialog component
export const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 10px; /* Adjust the border radius as needed */
  }
`;
