/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
import dateformat from 'dateformat';
export function convertBackendDate(date) {
  if (!date) {
    return null;
  }

  return date
    .slice(6, -2)
    .split('+')
    .reduce((result, number) => result + parseInt(number), 0);
}

export function convertBackendDateLastMessage(LastMessage) {
  if (!LastMessage) {
    return null;
  }
  let date = LastMessage.MessageDateTime;
  date = date
    .slice(6, -2)
    .split('+')
    .reduce((result, number) => result + parseInt(number), 0);
  return new Date(date).getTime();
}

export const getStringDate = (date) => {
  if (date) {
    let today = new Date();
    let paramDate = new Date(date);
    today.setHours(0, 0, 0, 0);
    paramDate.setHours(0, 0, 0, 0);

    let stringDate = dateformat(date, 'dd/mm/yyyy HH:MM');
    if (today.getTime() === paramDate.getTime()) {
      stringDate = dateformat(date, 'HH:MM');
    }
    return stringDate;
  }
  return '';
};

export const CompareLists = ({ list1 = [], list2 = [] }) => {
  if (list1.length === 0 || list2.length === 0) {
    return false;
  }

  if (list1[list1.length - 1].Guid !== list2[list2.length - 1].Guid) {
    return true;
  }
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

export const streamFile = (file) =>
  new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    } else {
      resolve(null);
    }
  });

export const giveNames = (name = '') => {
  let string = [];
  string = name.split(' ');
  if (string.length > 1) {
    return {
      Name: string[0],
      LastName: string[string.length - 1],
    };
  } else {
    return {
      Name: string[0],
      LastName: string[0],
    };
  }
};

export const sortList = (list = []) => {
  list.sort((a, b) => {
    let nameA = `${a.LastName.toLowerCase()} ${a.Name.toLowerCase()}`;
    let nameB = `${b.LastName.toLowerCase()} ${b.Name.toLowerCase()}`;
    if (nameA > nameB) {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }
  });
};
// prettier-ignore
var expressao_regular_url = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

function isUrl(texto) {
  return expressao_regular_url.test(texto);
}

function urlTruncada(url) {
  var limite_1 = 30;
  var limite_2 = 15;
  var reticencias = '[...]';
  if (url.length > limite_1 + limite_2 + reticencias.length) {
    url =
      url.substring(0, limite_1) +
      reticencias +
      url.substring(url.length - limite_2);
  }
  return url;
}

export function autoUrl(texto) {
  var texto_saida = '';
  var token = '';
  var caractere_fim_texto = String.fromCharCode(3);
  var separadores = ['\r', '\n', ' ', caractere_fim_texto];
  var caractere = '';
  var length_texto = texto.length;
  texto += caractere_fim_texto;
  for (var i in texto) {
    caractere = texto[i];
    if (separadores.indexOf(caractere) >= 0) {
      if (token) {
        if (isUrl(token)) {
          texto_saida +=
            '<a href="' +
            (token.search('://') < 0 ? 'http://' : '') +
            token +
            '" target="_blank">' +
            urlTruncada(token) +
            '</a>';
        } else {
          texto_saida += token;
        }
        token = '';
        if (parseInt(i) < length_texto) {
          texto_saida += caractere;
        }
      }
    } else {
      token += caractere;
    }
  }
  return texto_saida;
}
