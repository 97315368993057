import React, { useEffect, useState, useCallback } from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { UpdatePollMessage } from '../../services/chat';

import {
  PollTitleStyle,
  PollSubtitle,
  PollOptionStyle,
  CustomCheckbox,
  StyledLinearProgress,
} from './styles';

const ViewPoll = ({ message, userGuid }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pollData, setPollData] = useState(null);
  const handleCheckboxChange = (event, selection) => {
    if (event.target.checked) {
      let removeUserInPollOption = null;

      // Handle Multiselect
      if (!pollData.multiSelect) {
        removeUserInPollOption = pollData.PollOptions.map((option) => ({
          ...option,
          isSelectedBy: option.isSelectedBy.filter((id) => id !== userGuid),
        }));
      }

      // add userId
      if (!selection.isSelectedBy.includes(userGuid)) {
        const option = {
          ...selection,
          isSelectedBy: [...selection.isSelectedBy, userGuid],
        };

        const updatePollOption = {
          ...pollData,
          PollOptions: pollData.multiSelect
            ? pollData.PollOptions.map((op) =>
                op.id === selection.id ? option : op
              )
            : removeUserInPollOption.map((op) =>
                op.id === selection.id ? option : op
              ),
        };

        setPollData(updatePollOption);
        updatePollMessage(updatePollOption);
      }
    } else {
      // remove userId
      const optionToUpdate = pollData.PollOptions.find(
        (option) => option.id === selection.id
      );

      if (optionToUpdate) {
        const updatedOptions = pollData.PollOptions.map((option) => {
          if (option.id === selection.id) {
            return {
              ...option,
              isSelectedBy: option.isSelectedBy.filter((id) => id !== userGuid),
            };
          }
          return option;
        });

        const updatePollOption = {
          ...pollData,
          PollOptions: updatedOptions,
        };
        setPollData(updatePollOption);
        updatePollMessage(updatePollOption);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updatePollMessage = useCallback(
    async (data) => {
      try {
        const updatedMessage = { ...message, Message: JSON.stringify(data) };
        const result = await UpdatePollMessage({
          staffMessage: updatedMessage,
        });
        handleClose();
      } catch (error) {
        console.error('Error updating poll message:', error);
      }
    },
    [message]
  );

  useEffect(() => {
    let pollMessage = null;
    try {
      pollMessage = JSON.parse(message.Message);
      const updatedPollMessage = {
        ...pollMessage,
        PollOptions: pollMessage.PollOptions.map((option) => ({
          ...option,
          isSelectedBy: option.isSelectedBy || [],
        })),
      };

      setPollData(updatedPollMessage);
    } catch (error) {
      console.error('Invalid JSON:', error);
      setPollData(null);
    }
  }, [message]);

  if (!pollData) {
    return null;
  }

  return (
    <div>
      <PollTitleStyle>{pollData.PollTitle}</PollTitleStyle>
      <PollSubtitle>
        <p> {pollData.multiSelect ? 'select one or more' : 'select one'} </p>
      </PollSubtitle>
      <PollOptionStyle>
        {pollData.PollOptions.map((option) => (
          <div className="pollOptionContainer" key={option.id}>
            <div className="text">
              {
                <CustomCheckbox
                  id={`option${option.id}`}
                  name="pollOption"
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={
                    <CheckCircleOutlineIcon style={{ color: '#007bff' }} />
                  }
                  checked={option.isSelectedBy.includes(userGuid)}
                  onChange={(event) => handleCheckboxChange(event, option)}
                />
              }
              <label htmlFor={`option${option.id}`}> {option.text}</label>
              <span> {option.isSelectedBy.length} </span>
            </div>
            <div className="progressbar">
              <StyledLinearProgress
                variant="determinate"
                value={option.isSelectedBy.length > 0 ? 100 : 0}
                color="secondary"
              />
            </div>
          </div>
        ))}
      </PollOptionStyle>
    </div>
  );
};

export default ViewPoll;
