import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, DialogContentText } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import {
  ValidatePasswordExpiryToken,
  SetPasswordFirstTime,
} from '../../services/auth';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import { Container, Content, Logo, LogoDialog } from './styles';
import {
  CustomDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  CustomeTextField,
  StyledDialogErrorTitle,
} from './styles';
import logo from '../../assets/images/logo.png';

const PasswordSet = () => {
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast(false);
  const { search } = useLocation();
  const [data, setData] = React.useState(null);

  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [newPasswordError, setNewPasswordError] = React.useState('');
  const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
  const [passwordRules, setPasswordRules] = React.useState(
    intl.formatMessage({ id: 'passwordRules' })
  );

  const query = useMemo(() => {
    const queryObject = {};
    const params = search.slice(1).split('&');

    params.forEach((param) => {
      const [key, value] = param.split('=');
      if (key) {
        queryObject[decodeURIComponent(key)] = value
          ? decodeURIComponent(value)
          : '';
      }
    });
    return queryObject;
  }, [search]);

  const querytoken = query['token'];

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    //setPortal(null);
    history.push('/');
  }, [history]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await ValidatePasswordExpiryToken(querytoken);
        if (result.ValidatePasswordExpiryTokenResult) {
          setData(result);
        } else {
          throw new Error('Sorry validity token could not be verified.');
        }
      } catch (err) {
        console.log(`${err.message}`);
        addToast({
          title: intl.formatMessage({ id: 'PasswordTokenNotVerfiedError' }),
          type: 'error',
        });
      } finally {
      }
    };

    fetchData();
  }, [querytoken]);

  const validatePassword = (password) => {
    const minLength = 12;
    const uppercase = /[A-Z]/;
    const lowercase = /[a-z]/;
    const number = /[0-9]/;
    const specialChar = /[^A-Za-z0-9\s]/;
    const invalidChar = /[\\/]/;

    if (password) {
      if (password.length < minLength) {
        return intl.formatMessage({ id: 'PasswordTooShort' });
      }
      if (!uppercase.test(password)) {
        return intl.formatMessage({ id: 'PasswordUppercaseRequired' });
      }
      if (!lowercase.test(password)) {
        return intl.formatMessage({ id: 'PasswordLowercaseRequired' });
      }
      if (!number.test(password)) {
        return intl.formatMessage({ id: 'PasswordNumberRequired' });
      }
      if (!specialChar.test(password)) {
        return intl.formatMessage({ id: 'PasswordSpecialCharRequired' });
      }
      if (invalidChar.test(password)) {
        return intl.formatMessage({ id: 'PasswordInvalidChar' });
      }
      return '';
    } else {
      return intl.formatMessage({ id: 'NewPasswordRequired' });
    }
  };

  const handleSave = async () => {
    let hasError = false;

    const newPasswordError = validatePassword(newPassword);
    if (newPasswordError) {
      setNewPasswordError(newPasswordError);
      hasError = true;
    } else {
      setNewPasswordError('');
    }

    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(
        intl.formatMessage({ id: 'PasswordsDoNotMatch' })
      );
      hasError = true;
    } else {
      setConfirmPasswordError('');
    }

    if (!hasError) {
      const result = await SetPasswordFirstTime({
        userId: data.userId,
        userName: data.userName,
        newPassword,
      });
      if (result.SetPasswordFirstTimeUserResult) {
        addToast({
          title: intl.formatMessage({ id: 'passwordUpdateNotification' }),
          type: 'success',
        });
        cancel();
        logout();
      } else {
        addToast({
          title: intl.formatMessage({ id: 'passwordUpdateError' }),
          type: 'error',
        });
      }
    }
  };

  const handleNewPasswordBlur = () => {
    const error = validatePassword(newPassword);
    setNewPasswordError(error);
  };

  const handleConfirmPasswordBlur = () => {
    if (confirmPassword && newPassword !== confirmPassword) {
      setConfirmPasswordError(
        intl.formatMessage({ id: 'PasswordsDoNotMatch' })
      );
    } else {
      setConfirmPasswordError('');
    }
  };

  const cancel = () => {
    setNewPassword('');
    setConfirmPassword('');
    setNewPasswordError('');
    setConfirmPasswordError('');
    //handleCancel();
  };
  return (
    <Container>
      {!data ? (
        <Container>
          <Content>
            <Logo src={logo} alt="squre" />
            <StyledDialogErrorTitle>
              {intl.formatMessage({
                id: 'SetPasswordTokenExpired',
              })}
            </StyledDialogErrorTitle>
          </Content>
        </Container>
      ) : (
        <Container>
          <Content>
            <LogoDialog src={logo} alt="squre" />
            <CustomDialog open={true} maxWidth="sm" fullWidth={true}>
              <StyledDialogTitle>
                {intl.formatMessage({ id: 'SetPassword' })}
              </StyledDialogTitle>
              <StyledDialogContent>
                <DialogContentText
                  dangerouslySetInnerHTML={{ __html: passwordRules }}
                />

                <CustomeTextField
                  id="new-password"
                  fullWidth
                  label={intl.formatMessage({ id: 'NewPassword' })}
                  value={newPassword}
                  color="secondary"
                  error={Boolean(newPasswordError)}
                  helperText={newPasswordError}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onBlur={handleNewPasswordBlur}
                />
                <CustomeTextField
                  id="confirm-password"
                  fullWidth
                  label={intl.formatMessage({ id: 'ConfirmNewPassword' })}
                  value={confirmPassword}
                  color="secondary"
                  error={Boolean(confirmPasswordError)}
                  helperText={confirmPasswordError}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={handleConfirmPasswordBlur}
                />
              </StyledDialogContent>
              <StyledDialogActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleSave}
                >
                  {intl.formatMessage({ id: 'SetPassword' })}
                </Button>
              </StyledDialogActions>
            </CustomDialog>
          </Content>
        </Container>
      )}
    </Container>
  );
};

export default PasswordSet;
