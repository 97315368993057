import React from 'react';
import { AuthProvider } from './auth';
import { ConversationProvider } from './conversation';
import { ChatMessagesProvider } from './chat';
import { GroupsProvider } from './groups';
import { LoadingProvider } from './loading';
import { ThemeProvider } from '@material-ui/core/styles';
import material from '../styles/material';
import { MainProvider } from './main';
import { ContactsProvider } from './contacts';
import { ToastProvider } from './toast';
import Wrapper from '../components/wrapper';
const AppProvider = ({ children }) => {
  return (
    <Wrapper>
      <ToastProvider>
        <LoadingProvider>
          <AuthProvider>
            <MainProvider>
              <GroupsProvider>
                <ContactsProvider>
                  <ConversationProvider>
                    <ChatMessagesProvider>
                      <ThemeProvider theme={material}>{children}</ThemeProvider>
                    </ChatMessagesProvider>
                  </ConversationProvider>
                </ContactsProvider>
              </GroupsProvider>
            </MainProvider>
          </AuthProvider>
        </LoadingProvider>
      </ToastProvider>
    </Wrapper>
  );
};
export default AppProvider;
